import axios from "axios";
import UserInfo from "@/contracts/generic/UserInfo";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import { Subject, Subscription } from "rxjs";
import { bufferTime, map, take } from "rxjs/operators";
import store from "@/store";

class UserService {
  lookup: { [key: string]: UserInfo } = {};
  fetcher = new Subject<string>();
  fetchBuffer = new Subject<{ [key: string]: UserInfo }>();

  constructor() {
    this.fetcher.pipe(bufferTime(2000)).subscribe(async (val) => {
      val = val.filter(onlyUnique);
      val = val.filter((v) => !!v && v != "" && v != "$nan");
      val = val.map((v) => v.replaceAll("'", ""));
      if (val.length > 0) {

        while(!store.state.activity?.currentActivity?.id){
          await delay(2000);
        }

        return await axios
          .post(`${store.state.appConfig.apiBaseUrl}api/v2/users/bulk`, val)
          .then((response) => {
            if (response?.data) {
              for (const el of Array.from<UserInfo>(response?.data)) {
                this.lookup[el.id] = el;
              }
              this.fetchBuffer.next(this.lookup);
            }
          });
      }
    });
  }

  getUsersByIds(ids: string[]): Promise<UserInfo[]> {
    ids = ids.filter((value) => value && value !== "");
    if (ids.length <= 0) return Promise.resolve([]);

    const collector: UserInfo[] = [];
    let allFound = true;
    for (const id of ids) {
      if (!this.lookup[id]) {
        this.fetcher.next(id);
        allFound = false;
      } else {
        collector.push(this.lookup[id]);
      }
    }

    if (allFound) {
      return Promise.resolve(collector);
    } else {
      return this.fetchBuffer
        .pipe(
          take(1),
          map((lookup) => {
            return ids.map((id) => lookup[id]);
          })
        )
        .toPromise();
    }
    /*
    return Promise.resolve(
      ids.map((value) => {
        if (value === "4977a7d4-9bb6-ec11-983f-00224883cc27") {
          return {
            id: value as string,
            email: "someemail",
            firstName: "Tobias",
            lastName: "Inspecteur",
            fullName: "Tobias Inspecteur",
            aannemer: "-",
            language: "NL",
          };
        }
        if (value === "89337a63-e790-4b42-9277-aa6b3c3d1ebd") {
          return {
            id: value as string,
            email: "tobias.vanderpulst@ae.be",
            firstName: "Tobias",
            lastName: "Van der Pulst",
            fullName: "Tobias Van der Pulst",
            aannemer: "-",
            language: "NL",
          };
        } else {
          return {
            id: value as string,
            email: "string",
            firstName: "Arnout",
            lastName: "Van Nieuwenhove",
            fullName: "Arnout Van Nieuwenhove",
            aannemer: "-",
            language: "NL",
          };
        }
      })
    );*/
  }
}

export const userServiceV2 = new UserService();

function delay(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
