import { ContextAware, DetailContext } from "@/app/contexts/detail.context";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import Vue from "vue";
import store from "@/store";

export class ExternalContext implements ContextAware{
  data: { [key: string]: any } = {};
  public setData(key: string, rule: any): ExternalContext {
    this.data[key] = rule;
    return this;
  }


  constructor() {
    this.setData("activity", store.state.activity.currentActivityDefinition);
    const user = store.state.user;
    this.setData("user", {
      contactId: user.contactId,
      organizationId: user.organizationId,
      language: user.language,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      roleIds: user.roleIds
    });
    this.setData("bccaId", store.state.appConfig.bccaId);
  }

  public resolvePlaceholders(data: any): any {
    if (typeof data === "string") {
      return this.resolvePlaceholdersString(data);
    }
    return JSON.parse(this.resolvePlaceholdersString(JSON.stringify(data)));
  }

  private resolvePlaceholdersString(str: any): any {
    const calculatedReplaceRegex = /\${([^$}]*)}/;

    let replaceRegex;
    let c = 0;
    while ((replaceRegex = calculatedReplaceRegex.exec(str)) && c < 1000) {
      const value = this.resolveDataPath(replaceRegex[1]);
      str = str.replaceAll(replaceRegex[0], value ? value : "$nan");
      //console.log("found regex", JSON.stringify(replaceRegex), value, str);
      c++;
    }
    return str;
  }

  public resolveDataPath(dataPath: string): any {
    if (dataPath.startsWith("data.")) dataPath = dataPath.replace("data.", "");
    return getObjectContentsFromPath(dataPath, this.data);
  }

  public inherit(
    detailContext: DetailContext | ExternalContext
  ): ExternalContext {
    if (!detailContext || !detailContext.data) return this;
    Object.entries(detailContext.data).forEach((value) => {
      this.data[value[0]] = value[1];
    });
    return this;
  }

  getData(): { [p: string]: any } {
    return this.data;
  }
}
