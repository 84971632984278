import EventBase from "@/app/events/event-base";
import { DetailContext } from "@/app/contexts/detail.context";
import router from "@/router";

export default class OverviewRowClickNavigationEvent extends EventBase {
  getCallBack(detailsContext?: DetailContext, payload?: any): any {
    router.push({
      name: payload.metadata.route,
      params: {
        id: payload.item.item["id"],
      },
    });
  }
}
