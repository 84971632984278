import DeleteDossierEvent from "@/app/events/dossier/delete-dossier.event";
import EventBase from "@/app/events/event-base";
import EditDossierEvent from "@/app/events/dossier/edit-dossier.event";
import ModifyLotsEvent from "@/app/events/dossier/modify-lots.event";
import CancelDossierEvent from "@/app/events/dossier/cancel-dossier.event";
import CompleteDossierEvent from "@/app/events/dossier/complete-dossier.event";
import ShowDialogEvent from "@/app/events/dossier/showdialog.event";
import ShowCheckinEvent from "@/app/events/dossier/show-checkin.event";
import LotConformityGenerationEvent from "@/app/events/dossier/lot-conformity-generation.event";
import OverviewRowClickNavigationEvent from "@/app/events/navigation/overview-row-click-navigation.event";
import DeleteDocumentEvent from "@/app/events/document/delete-document.event";
import UpdateDocumentEvent from "@/app/events/document/update-document.event";

interface EventDictionaryInterface {
  [key: string]: EventBase;
}

export const EventDictionary: EventDictionaryInterface = {
  deleteDossier: new DeleteDossierEvent(),
  editDossier: new EditDossierEvent(),
  modifyLotsEvent: new ModifyLotsEvent(),
  cancelDossier: new CancelDossierEvent(),
  completeDossier: new CompleteDossierEvent(),
  showDialog: new ShowDialogEvent(),
  checkin: new ShowCheckinEvent(),
  lotConformityGenerationEvent: new LotConformityGenerationEvent(),
  rowClickNavigation: new OverviewRowClickNavigationEvent(),
  deleteDocument: new DeleteDocumentEvent(),
  updateDocument: new UpdateDocumentEvent(),
};
