









































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormComponentSelect from "@/app/dynamic-components/forms/form-components/components/depricated/form-component-select.vue";
import { documentServiceV2 } from "@/app/services/document.service";
import { userServiceV2 } from "@/app/services/user.service";
import { organisationService } from "@/app/services/organisation.service";
import { ExternalContext } from "@/app/contexts/externalContext";
import { roleService } from "@/app/services/role.service";
import { DataTableHeader } from "vuetify";

@Component({
  components: { FormComponentSelect },
})
export default class ShareDocumentDialogComponent extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: {} })
  rules!: { [key: string]: boolean };

  @Prop({
    default: () => {
      return null;
    },
  })
  documentItem!: any;
  @Watch("documentItem", { immediate: true, deep: true })
  async setRelatedDocumentProperties(newValue: any, oldValue: any) {
    this.isLoading = true;
    const documentInvolvedParties = await documentServiceV2.getInvolvedParties(
      newValue.id
    );
    const involvedUsers = documentInvolvedParties.filter(
      (party) => party.contactType === "USER"
    );
    const involvedOrganisations = documentInvolvedParties.filter(
      (party) => party.contactType === "ORGANISATION"
    );

    if (involvedUsers && involvedUsers.length > 0) {
      const userIds = involvedUsers.map((user) => user.contactId);
      const usersResult = await userServiceV2.getUsersByIds(userIds);
      usersResult.forEach((result) => {
        const mappedResult = {
          id: result.id,
          name: `${result.firstName} ${result.lastName}`,
          contactType: "USER",
        };
        this.involvedParties.push(mappedResult);
        this.desiredParties.push(mappedResult);
      });
    }

    if (involvedOrganisations && involvedOrganisations.length > 0) {
      const organisationIds = involvedOrganisations.map(
        (organisation) => organisation.contactId
      );
      const organisationsResult =
        await organisationService.getOrganisationsByIds(organisationIds);
      organisationsResult.forEach((result) => {
        const mappedResult = {
          id: result.id,
          name: result.name,
          contactType: "ORGANISATION",
        };
        this.involvedParties.push(mappedResult);
        this.desiredParties.push(mappedResult);
      });
    }
    this.isLoading = false;
  }

  @Prop({
    default: () => {
      return new ExternalContext();
    },
  })
  externalContext!: ExternalContext;

  @Prop({ default: "2000" })
  dialogWidth!: string;

  private isLoading = false;

  private selectedContactType: "USER" | "ORGANISATION" | "ACTIVITY" = "USER";
  private roles: any[] = [];
  private selectedRoles: any[] = [];
  private involvedParties: any[] = [];
  private eligibleParties: any[] = [];
  private desiredParties: any[] = [];
  private selectedParties: any[] = [];

  mounted(): void {
    this.reset();
  }

  private get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t("share.headers.name").toString(),
        value: "name",
      },
      {
        text: this.$t("share.headers.contactType").toString(),
        value: "contactType",
      },
      {
        text: this.$t("share.headers.actions").toString(),
        value: "actions",
        sortable: false,
        align: "end",
      },
    ];
  }

  private get contactTypes(): string[] {
    return this.rules.isBCCAUser
      ? ["USER", "ORGANISATION", "ACTIVITY"]
      : ["USER", "ORGANISATION"];
  }

  private get somePartiesSelected(): boolean {
    return this.selectedParties.length > 0 && !this.selectedAllParties;
  }

  private get selectedAllParties(): boolean {
    return this.selectedParties.length === this.eligibleParties.length;
  }

  private toggleSelectAllParties(): void {
    this.selectedParties = this.selectedAllParties
      ? []
      : this.eligibleParties.slice();
  }

  private get someRolesSelected(): boolean {
    return this.selectedRoles.length > 0 && !this.selectedAllRoles;
  }

  private get selectedAllRoles(): boolean {
    return this.selectedRoles.length === this.roles.length;
  }

  private toggleSelectAllRoles(): void {
    this.selectedRoles = this.selectedAllRoles ? [] : this.roles.slice();
    this.updatePartiesList(this.selectedRoles);
  }

  private closeDialog(): void {
    this.reset();
    this.show = false;
  }

  private async addParty(): Promise<void> {
    if (this.selectedContactType === "ACTIVITY") {
      this.isLoading = true;
      await this.getAllPartiesInRolesForSharing();
      this.isLoading = false;
    } else {
      this.desiredParties = this.desiredParties.concat(this.selectedParties);
    }
    this.resetSelection();
  }

  private removeParty(partyId): void {
    const removeDesiredPartyIndex = this.desiredParties.findIndex(
      (party) => party.id === partyId
    );
    this.desiredParties.splice(removeDesiredPartyIndex, 1);
    this.resetSelection();
  }

  private async updatePartiesList(newValue: any): Promise<void> {
    // when value is part of contactType select, reset values for other selects to keep data clean
    if (this.contactTypes.includes(newValue)) {
      this.resetSelection();
    }

    this.isLoading = true;
    if (this.selectedRoles && this.selectedRoles.length > 0) {
      for (const role of this.selectedRoles) {
        let partiesResult: any[] = [];

        if (this.selectedContactType == "USER") {
          partiesResult = await this.getUserDataForParties(role.id);
        }

        if (this.selectedContactType == "ORGANISATION") {
          partiesResult = await this.getOrganisationDataForParties(role.id);
        }

        partiesResult.forEach((party) => {
          if (
            !this.eligibleParties.find(
              (eligibleParty) => eligibleParty.id === party.id
            )
          ) {
            this.eligibleParties.push(party);
          }
        });
      }
    } else {
      this.eligibleParties = [];
    }

    this.isLoading = false;
  }

  private reset(): void {
    this.selectedContactType = "USER"; // set back to default value
    this.selectedRoles = [];
    this.selectedParties = [];
    this.involvedParties = [];
    this.desiredParties = [];
    this.resetSelection();
  }

  private async getUserDataForParties(role: string): Promise<any[]> {
    const eligibleUsers = this.rules.isBCCAParty
      ? await roleService.getEligibleUsersForRole(role, true)
      : await roleService.getEligibleUsersForRole(
          role,
          false,
          this.$store.state.user.organizationId
        );

    return eligibleUsers
      .filter(
        (user) => !this.desiredParties.find((party) => party.id === user.id)
      )
      .map((user) => {
        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          contactType: "USER",
        };
      });
  }

  private async getOrganisationDataForParties(role: string): Promise<any[]> {
    return (await roleService.getEligableOrganisations(role))
      .filter(
        (organisation) =>
          !this.desiredParties.find((party) => party.id === organisation.id)
      )
      .map((organisation) => {
        return {
          id: organisation.id,
          name: organisation.name,
          contactType: "ORGANISATION",
        };
      });
  }

  private resetSelection(): void {
    // necessary otherwise selects will not reset and eslint will give errors
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.partySelect?.reset();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.roleSelect?.reset();
    this.eligibleParties = [];

    if (this.selectedContactType === "USER") {
      this.setAvailableRoles(this.externalContext.data.userRoles);
    }

    if (this.selectedContactType === "ORGANISATION") {
      this.setAvailableRoles(this.externalContext.data.organisationRoles);
    }
  }

  setAvailableRoles(roles: any[]): void {
    this.roles = roles;
  }

  public async saveShareModifications(): Promise<void> {
    this.isLoading = true;

    await this.removeUnsharedParties();
    await this.addSharedParties();

    this.isLoading = false;
    this.closeDialog();
  }

  private async removeUnsharedParties(): Promise<void> {
    for (const involvedParty of this.involvedParties) {
      if (
        !this.desiredParties.find(
          (desiredParty) => desiredParty.id === involvedParty.id
        )
      ) {
        await documentServiceV2.removePartyFromDocument(
          this.documentItem.id,
          involvedParty.id
        );
      }
    }
  }

  private async addSharedParties(): Promise<void> {
    for (const desiredParty of this.desiredParties) {
      if (
        !this.involvedParties.find(
          (involvedParty) => involvedParty.id === desiredParty.id
        )
      ) {
        await documentServiceV2.addPartyToDocument(
          this.documentItem.id,
          desiredParty.id,
          desiredParty.contactType
        );
      }
    }
  }

  private async getAllPartiesInRolesForSharing(): Promise<void> {
    // get all users in roles
    for (const userRole of this.externalContext.data.userRoles) {
      const userParties = await this.getUserDataForParties(userRole.id);
      if (userParties && userParties.length > 0) {
        this.desiredParties = this.desiredParties.concat(userParties);
      }
    }
    // get all organisations in roles
    for (const organisationRole of this.externalContext.data
      .organisationRoles) {
      const organisationParties = await this.getOrganisationDataForParties(
        organisationRole.id
      );
      if (organisationParties && organisationParties.length > 0) {
        this.desiredParties = this.desiredParties.concat(organisationParties);
      }
    }
  }
}
