import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{key:_vm.documentItem.id,attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","eager":true,"width":_vm.dialogWidth},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.documentItem.name)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$t("my-documents.shared"))+" ")]),_c(VCardText,[_c(VRow,{staticClass:"mb-1 px-6"},[_c(VSelect,{staticClass:"px-2",attrs:{"items":_vm.contactTypes,"label":_vm.$t('share.contact-type-select.label')},on:{"change":_vm.updatePartiesList},model:{value:(_vm.selectedContactType),callback:function ($$v) {_vm.selectedContactType=$$v},expression:"selectedContactType"}}),(_vm.selectedContactType !== 'ACTIVITY')?_c(VAutocomplete,{ref:"roleSelect",staticClass:"px-2",attrs:{"items":_vm.roles,"label":_vm.$t('share.role-select.label'),"no-data-text":_vm.$t('general.no-data').toString(),"item-text":"label","item-value":"id","multiple":"","small-chips":"","clearable":""},on:{"change":_vm.updatePartiesList},scopedSlots:_vm._u([(_vm.roles.length > 0)?{key:"prepend-item",fn:function(){return [_c(VListItem,{on:{"click":_vm.toggleSelectAllRoles}},[_c(VListItemAction,[_c(VSimpleCheckbox,{attrs:{"indeterminate":_vm.someRolesSelected && !_vm.selectedAllRoles,"value":_vm.selectedAllRoles},on:{"click":_vm.toggleSelectAllRoles}})],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t("share.select-all"))+" ")])],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}:null],null,true),model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}}):_vm._e(),(
            _vm.selectedContactType !== 'ACTIVITY' &&
            _vm.selectedRoles &&
            _vm.selectedRoles.length > 0
          )?_c(VAutocomplete,{ref:"partySelect",staticClass:"px-2 mr-2",attrs:{"items":_vm.eligibleParties,"item-text":"name","item-value":"id","label":_vm.$t('share.party-select.label'),"no-data-text":_vm.$t('general.no-data').toString(),"return-object":"","multiple":"","small-chips":"","clearable":""},scopedSlots:_vm._u([(_vm.eligibleParties.length > 0)?{key:"prepend-item",fn:function(){return [_c(VListItem,{on:{"click":_vm.toggleSelectAllParties}},[_c(VListItemAction,[_c(VSimpleCheckbox,{attrs:{"indeterminate":_vm.somePartiesSelected && !_vm.selectedAllParties,"value":_vm.selectedAllParties},on:{"click":_vm.toggleSelectAllParties}})],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t("share.select-all"))+" ")])],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}:null],null,true),model:{value:(_vm.selectedParties),callback:function ($$v) {_vm.selectedParties=$$v},expression:"selectedParties"}}):_vm._e(),_c(VBtn,{staticClass:"mt-3",attrs:{"color":"primary","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":_vm.addParty}},[_vm._v(" "+_vm._s(_vm.$t("general.add"))+" ")])],1),_c(VDivider),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.desiredParties,"item-key":"id","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.removeParty(item.id)}}},[_vm._v(" mdi-close ")])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":"","disabled":_vm.isLoading},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.saveShareModifications}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }