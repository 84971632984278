import {
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";
import ActivityDefinition from "@/app/models/activity/activity-definition.model";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import ActivityModel from "@/app/models/activity/activity.model";
import { activityServicev2 } from "@/app/services/activity.service";
import { ActivitySecurity } from "@/app/models/activity/security.model";
import store from "@/store";
import axios from "axios";
import { authenticationService } from "@/services/authentication.service";
import analyticsService from "@/services/analytics.service";

@Module
export default class ActivityModule extends VuexModule {
  currentActivityDefinition: ActivityDefinitionModel = {
    id: "",
    version: "",
    name: "",
    dashboard: { definitionId: "" },
    products: {
      overviewDefinitionId: "",
      createProductDialogDefinitionId: "",
      updateProductDialogDefinitionId: "",
      manufacturers: [],
      materialTypes: [],
    },
    planning: {
      overviewDefinitionId: "",
      updatePlanningDialogDefinitionId: "",
    },
    checkins: {
      overviewDefinitionId: "",
      completeCheckinDialogDefinitionId: "",
      createCheckinDialogDefinitionId: "",
      uploadFilesCheckinDialogDefinitionId: "",
      detailsCheckinDialogDefinitionId: "",
      regardingType: "",
      canCreateNew: "false",
    },
    dossiers: {
      overviewDefinitionId: "",
      entries: [],
    },
    generalDocuments: {
      overviewDefinitionId: "",
      detailsDocumentDialogDefinitionId: "",
      modifyDocumentDialogDefinitionId: "",
      uploadDocumentDialogDefinitionId: "",
      shareDialogDefinition: "",
      rules: [],
      entries: []
    },
    security: {
      dossiers: [],
      tabs: {},
      home: {},
    },
    activityData: {},
  };
  eligibleActivities: ActivityModel[] = [];
  currentActivity: ActivityModel = { id: "", name: "nan" };
  currentActivitySecurity: ActivitySecurity = {
    dossiers: {},
    tabs: {},
    home: {},
  };

  @Mutation
  setCurrentActivityDefinition(activity: ActivityDefinition): void {
    // analyticsService.setUserActivity(activity);
    this.currentActivityDefinition = activity;
  }

  @Mutation
  setEligibleActivities(activities: ActivityModel[]): void {
    this.eligibleActivities = activities;
  }
  @MutationAction({
    mutate: [
      "currentActivity",
      "currentActivityDefinition",
      "currentActivitySecurity",
    ],
  })
  async setCurrentActivity(activity: ActivityModel) {
    analyticsService.setUserActivity(activity.name);
    localStorage.setItem("currentActivity", activity.id);
    authenticationService.setAxiosActivityHeader();

    const activityDefinition = await activityServicev2.getActivityDefinition(
      activity.id
    );

    const security: ActivitySecurity = {
      dossiers: {},
      tabs: {},
      home: {},
    };

    const roleIds: string[] = store.state.user.roles.map((role) => role.id);

    if (activityDefinition?.security?.dossiers) {
      activityDefinition.security.dossiers.forEach((securityByRoles) => {
        const other: { [key: string]: boolean } = {};
        if (securityByRoles.other) {
          Object.keys(securityByRoles.other).forEach((key) => {
            other[key] = securityByRoles.other[key].some((v) =>
              roleIds.includes(v)
            );
          });
        }

        security.dossiers[securityByRoles.dossierDefinitionId] = {
          dossierDefinitionId: securityByRoles.dossierDefinitionId,
          create: securityByRoles.create.some((v) => roleIds.includes(v)),
          edit: securityByRoles.edit.some((v) => roleIds.includes(v)),
          read: securityByRoles.read.some((v) => roleIds.includes(v)),
          list: securityByRoles.list.some((v) => roleIds.includes(v)),
          other: other,
          singleDossier: securityByRoles.singleDossier.some((v) =>
            roleIds.includes(v)
          ),
        };
      });
    }

    const tabSecurity = {};
    if (activityDefinition?.security?.tabs) {
      Object.entries(activityDefinition.security.tabs).forEach((entry) => {
        tabSecurity[entry[0]] =
          entry[1].some((v) => roleIds.includes(v)) || false;
      });
    }

    security.tabs = tabSecurity;
    security.home = activityDefinition?.security?.home;

    return {
      currentActivity: activity,
      currentActivityDefinition: activityDefinition,
      currentActivitySecurity: security,
    };
  }
}
