

















































import { Component, Watch } from "vue-property-decorator";
import Autocomplete from "@/components/common/Autocomplete.vue";
import BaseDynamicComponent from "../form-component.base";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { DataTableHeader } from "vuetify";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

export type SelectMetaData = {
  items: unknown[] | string; //list in metadata | string resolver
  itemText: string;
  itemValue: string;

  mode: string; //list or select
  headers: DataTableHeader[];

  dataResolver: string;
  path: string; //not yet implemented. see overview select for data from overviews

  chips: boolean;
  clearable: boolean;
  dense: boolean;
  multiple: boolean;

  loading: boolean;
  deletableChips: boolean;

  translate: boolean;
  autoSetOneItem: boolean;
  sortAlphabetically: boolean;
};
@Component({
  components: {
    AutocompleteWithErrors,
    Autocomplete,
  },
})
export default class DynamicSelect extends BaseDynamicComponent<SelectMetaData> {
  selectedList: string[] | string = [];
  list: any[] = [];
  headers: Array<{ value: string; text: string; sortable: boolean }> = [];
  formDataChangedSubscription: Subscription | null = null;

  get displayList(): string {
    return this.list
      .filter((e) => {
        if (this.entry?.metadata?.itemValue) {
          return this.selectedList.includes(e[this.entry?.metadata?.itemValue]);
        } else {
          return this.selectedList.includes(e);
        }
      })
      .map((e) => {
        if (this.entry?.metadata?.itemText) {
          return e[this.entry?.metadata?.itemText];
        } else {
          return e;
        }
      })
      .map(value => {
        if(this.entry.metadata.translate){
          return this.$tc(value);
        }
        return value;
      })
      .join(", ");
  }

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry && entry.metadata) {
      if(entry.metadata.headers && Array.isArray(entry.metadata.headers)){
        this.headers = entry.metadata.headers.map(h => {
          return {
            value: h.value,
            sortable: h.sortable,
            text: this.$t(h.text) as string,
          };
        });
      }

      this.resolveListValues(entry);

      if (this.formDataChangedSubscription)
        this.formDataChangedSubscription.unsubscribe();
      if (entry.root)
        this.formDataChangedSubscription = entry.root.formDataEmittor
          .pipe(debounceTime(300))
          .subscribe(() => {
            this.resolveListValues(entry);
          });
    }
  }

  resolveListValues(entry: DynamicFormEntry) {
    if (entry.metadata.items && Array.isArray(entry.metadata.items)) {
      //entry in metadata
      this.list = entry.metadata.items;
    } else if (
      (entry.metadata.items && typeof entry.metadata.items === "string") ||
      entry.metadata.dataResolver
    ) {
      //entry in dataresolver
      const dataResolver = entry.metadata.dataResolver || entry.metadata.items;
      const resolvedItems = this.entry.root?.resolveDataPath(
        this.entry.root?.resolvePlaceholders(dataResolver)
      );
      //console.warn("new list 1", resolvedItems);

      if (!resolvedItems) {
        this.list = [];
      } else {
        if (Array.isArray(resolvedItems)) {
          if (entry.metadata.property) {
            this.list = resolvedItems.map((p) => p[entry.metadata.property]);
          } else if (this.entry.metadata.itemValue) {
            this.list = resolvedItems; // Mapping already happened, e.g. src/app/views/documents/documents-overview-view.vue > categories.
            // this.list = resolvedItems.map((p) => p[entry.metadata.itemValue]);
          } else {
            this.list = resolvedItems;
          }
        } else {
          if (!this.entry.metadata.itemText)
            this.entry.metadata.itemText = "value";
          if (!this.entry.metadata.itemValue)
            this.entry.metadata.itemValue = "key";
          this.list = Object.entries(resolvedItems).map((value) => {
            const o = {};
            o[this.entry.metadata.itemValue] = value[0];
            if (typeof value[1] !== "string") {
              const v: any = value[1];
              o[this.entry.metadata.itemText] = v[this.entry.metadata.itemText];
            } else {
              o[this.entry.metadata.itemText] = value[1];
            }
            return o;
          });
          //console.warn("new list", JSON.stringify(this.list));
        }
      }
    } else if (entry.metadata.path) {
      const url = entry.root?.resolvePlaceholders(entry.metadata.path);
      this.list = [];
    }
  }

  fillIndexerValue(indexerField: string, indexerSelector): any {
    const result = this.displayList;
    if("-" === result) return ""
    if("text" === indexerSelector) return result;

    if(Array.isArray(this.value)) return "" +  this.value.join(', ');
    return "" + this.value;
  }

  @Watch("selectedList")
  onSelectionChanged() {
    if(!this.selectedList){
      this.setValueView(this.selectedList);
      return;
    }

    if(this.entry.metadata.mode === 'list'){
      if(this.entry.metadata.itemValue){
        this.setValueView(Array.from(this.selectedList).filter(value => !!value).map(value => value[this.entry.metadata.itemValue]));
      }else{
        this.setValueView(this.selectedList);
      }
    }else{
      this.setValueView(this.selectedList);
    }
  }
  postSetValueView(value: any) {
    if (!value) {
      this.selectedList = [];
    } else {
      if(this.entry.metadata.mode === 'list'){
        const mappedList = Array.from(this.selectedList).filter(value => !!value).map(value => value[this.entry.metadata.itemValue]);
        const val = Array.from(value);
        if (JSON.stringify(value) !== JSON.stringify(mappedList)) {
          this.selectedList = this.list.filter(value1 => val.includes(value1[this.entry.metadata.itemValue]));
        }
      }else{
        if (JSON.stringify(value) !== JSON.stringify(this.selectedList)) {
          this.selectedList = value;
        }
      }
    }
  }

  beforeDestroy(): void {
    if (this.formDataChangedSubscription)
      this.formDataChangedSubscription.unsubscribe();
    this.formDataChangedSubscription = null;
  }
}
