























































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component, Watch } from "vue-property-decorator";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";

export type ComboboxMetaData = {
  items: unknown[] | string; //list in metadata | string resolver
  itemText: string;
  itemValue: string;
  chips: boolean;
  clearable: boolean;
  multiple: boolean;
  deletableChips: boolean;
  prependInnerIcon: string;
  translate: boolean;
  sortAlphabetically: boolean;
};

@Component({
  components: {},
})
export default class DynamicCombobox extends BaseDynamicComponent<ComboboxMetaData> {
  selectedList: string[] | string = [];
  list: any[] = ["test A", "test B"];
  searchInput = "";

  get displayList(): string {
    return this.list
      .filter((e) => {
        if (this.entry?.metadata?.itemValue) {
          return this.selectedList.includes(e[this.entry?.metadata?.itemValue]);
        } else {
          return this.selectedList.includes(e);
        }
      })
      .map((e) => {
        if (this.entry?.metadata?.itemText) {
          return e[this.entry?.metadata?.itemText];
        } else {
          return e;
        }
      })
      .map((value) => {
        if (this.entry.metadata.translate) {
          return this.$tc(value);
        }
        return value;
      })
      .join(", ");
  }

  addCustomInput() {
    if (this.searchInput && !this.selectedList.includes(this.searchInput)) {
      if (this.entry.metadata.multiple) {
        this.selectedList = [...this.selectedList, this.searchInput];
      } else {
        this.selectedList = this.searchInput;
      }
    }
  }

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry && entry.metadata) {
      if (entry.metadata.items && Array.isArray(entry.metadata.items)) {
        //entry in metadata
        this.list = entry.metadata.items;
      } else if (
        (entry.metadata.items && typeof entry.metadata.items === "string") ||
        entry.metadata.dataResolver
      ) {
        //entry in dataresolver
        const dataResolver =
          entry.metadata.dataResolver || entry.metadata.items;

        const resolvedItems = this.entry.root?.resolveDataPath(
          this.entry.root?.resolvePlaceholders(dataResolver)
        );
        //console.warn("new list 1", resolvedItems);
        if (!resolvedItems) {
          this.list = [];
        } else {
          if (Array.isArray(resolvedItems)) {
            if (entry.metadata.property) {
              this.list = resolvedItems.map((p) => p[entry.metadata.property]);
            } else if (this.entry.metadata.itemValue) {
              this.list = resolvedItems.map((p) => p[entry.metadata.itemValue]);
            } else {
              this.list = resolvedItems;
            }
          } else {
            if (!this.entry.metadata.itemText)
              this.entry.metadata.itemText = "value";
            if (!this.entry.metadata.itemValue)
              this.entry.metadata.itemValue = "key";
            this.list = Object.entries(resolvedItems).map((value) => {
              const o = {};
              o[this.entry.metadata.itemValue] = value[0];
              if (typeof value[1] !== "string") {
                const v: any = value[1];
                o[this.entry.metadata.itemText] =
                  v[this.entry.metadata.itemText];
              } else {
                o[this.entry.metadata.itemText] = value[1];
              }
              return o;
            });
            //console.warn("new list", JSON.stringify(this.list));
          }
        }
      } else if (entry.metadata.path) {
        const url = entry.root?.resolvePlaceholders(entry.metadata.path);
        this.list = [];
      }
    }
  }

  fillIndexerValue(indexerField: string, indexerSelector): any {
    const result = this.displayList;
    if ("-" === result) return "";
    if ("text" === indexerSelector) return result;

    if (Array.isArray(this.value)) return "" + this.value.join(", ");
    return "" + this.value;
  }

  @Watch("selectedList")
  onSelectionChanged() {
    this.setValueView(this.selectedList);
  }

  postSetValueView(value: any) {
    if (!value) {
      this.selectedList = [];
    } else {
      if (JSON.stringify(value) !== JSON.stringify(this.selectedList)) {
        this.selectedList = value;
      }
    }
  }
}
