import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',[(_vm.displayMode === 'editable')?[(!_vm.isUploading)?_c('div',[(_vm.entry.metadata.mode === 'dragAndDrop')?_c('DragAndDropFileInput',{staticClass:"mt-4",attrs:{"accept":_vm.entry.metadata.accept,"multiple":_vm.entry.metadata.multiple,"maxImageWidth":"200px","required":_vm.entry.hasRequiredValidator,"errors":_vm.errors,"error-messages":_vm.errorMessages},on:{"input":function($event){return _vm.onFileInputContentChanged($event)}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}):_c('FileInput',{staticClass:"mt-4",attrs:{"label":_vm.$tc(_vm.entry.name),"accept":_vm.entry.metadata.accept,"icon-on-right":_vm.entry.metadata.iconOnRight,"multiple":_vm.entry.metadata.multiple,"required":_vm.entry.hasRequiredValidator,"errors":_vm.errors,"error-messages":_vm.errorMessages},on:{"input":function($event){return _vm.onFileInputContentChanged($event)},"validityChanged":function($event){return _vm.onFileInputValidityChanged($event)}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1):_c('div',{staticClass:"mt-0"},[_c(VList,_vm._l((_vm.documents),function(document){return _c(VListItem,{key:document.documentId},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(document.name)}}),(
                document.name === _vm.currentUploadingDocument &&
                _vm.amountOfUploadedDocuments !== _vm.totalAmountOfDocuments
              )?_c(VProgressLinear,{attrs:{"indeterminate":_vm.isUploading,"active":_vm.isUploading,"color":"primary"}}):_vm._e(),(document.uploaded)?_c('div',[_c(VIcon,[_vm._v("mdi-check-all")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.document-uploaded")))])],1):_vm._e(),(!document.uploaded && document.documentId === 'nan')?_c('div',[_c(VIcon,[_vm._v("mdi-close")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.document-upload-failed")))])],1):_vm._e()],1)],1)}),1),_c('div',{staticClass:"pl-8 pt-0 pb-5"},[(_vm.amountOfUploadedDocuments !== _vm.totalAmountOfDocuments)?_c(VProgressCircular,{attrs:{"size":20,"width":2,"indeterminate":_vm.isUploading,"color":"primary"}}):_c(VIcon,[_vm._v(" mdi-check-all ")]),_c('span',{staticClass:"pt-3 pl-2"},[_vm._v(" "+_vm._s((_vm.amountOfUploadedDocuments + " " + (_vm.$t( "general.count-indicator" )) + " " + _vm.totalAmountOfDocuments + " " + (_vm.$t("general.uploading-documents"))))+" ")])],1)],1)]:_vm._e(),(_vm.displayMode === 'readonly')?_c('div',{staticClass:"readonly-wrapper d-block"},[_c('div',{staticClass:"readonly-wrapper__label"},[_vm._v(_vm._s(_vm.$t(_vm.entry.name)))]),(!_vm.documents || _vm.documents.length <= 0)?_c('div',{staticClass:"readonly-wrapper__value"},[_vm._v(" "+_vm._s("-")+" ")]):_vm._e(),_vm._l((_vm.documents),function(doc){return _c('div',{key:doc.documentId},[(doc.fetched && doc.uploaded)?_c('div',{staticClass:"readonly-wrapper__value download-file",on:{"click":function($event){return _vm.downloadFile(doc)}}},[_c(VIcon,{staticClass:"me-4"},[_vm._v(" mdi-download")]),_c('p',{staticClass:"ms-1",staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(doc.name))])],1):_c('div',{staticClass:"readonly-wrapper__value download-file"},[_c('loader',{staticStyle:{"display":"inline-block"},attrs:{"size":16}})],1)])})],2):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }