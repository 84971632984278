import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',[_c(VCard,{staticClass:"fill-height"},[_c(VCardTitle,[_c('h3',{staticClass:"font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.entry.name))+" ")])]),_c(VRow,[_c(VCol,[_c('overview-component',{staticClass:"px-3",attrs:{"overview-definition-id":_vm.metadata.overviewDefinitionId,"entityType":_vm.metadata.entityType,"path":_vm.metadata.path,"table-style":_vm.metadata.tableStyle,"additional-metadata":_vm.additionalMetadata,"modify-fetched-data":_vm.mapToOverviewData},on:{"rowClicked":_vm.rowClicked,"overviewControl":_vm.recieveOverviewControl}})],1)],1)],1),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","eager":true,"width":"800"},model:{value:(_vm.showWidgetDialog),callback:function ($$v) {_vm.showWidgetDialog=$$v},expression:"showWidgetDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-md-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t(("" + (_vm.metadata.dialogTitle))))+" ")]),_c(VCol,[_c('form-wrapper',{staticClass:"mt-6 mb-6",attrs:{"form-definition":_vm.dialogFormDefinition.form,"external-context":_vm.externalContext},on:{"dynamicForm":function($event){return _vm.recieveFormView($event)}}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"me-6",attrs:{"text":""},on:{"click":_vm.rowClicked}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),(_vm.rules.canDelete)?_c(VBtn,{staticClass:"me-2",staticStyle:{"color":"white !important"},attrs:{"loading":_vm.eventLoading,"color":"red"},on:{"click":function($event){return _vm.resolveEvent(_vm.metadata.dialogDeleteEvent)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])]):_vm._e(),(_vm.rules.canUpdate)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.eventLoading},on:{"click":function($event){return _vm.resolveEvent(_vm.metadata.dialogUpdateEvent)}}},[_vm._v(" "+_vm._s(_vm.$t("general.update"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }