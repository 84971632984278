import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',[(_vm.displayMode === 'editable')?[_c(VCombobox,{ref:"comboboxRef",attrs:{"prepend-inner-icon":_vm.entry.metadata.prependInnerIcon || undefined,"multiple":_vm.entry.metadata.multiple || false,"chips":_vm.entry.metadata.multiple || false,"translate":_vm.entry.metadata.translate || false,"deletable-chips":true,"clearable":_vm.entry.metadata.clearable || true,"disabled":false,"items":_vm.list,"loading":false,"sort-alphabetically":_vm.entry.metadata.sortAlphabetically || true,"item-text":_vm.entry.metadata.itemText || undefined,"item-value":_vm.entry.metadata.itemValue || undefined,"label":_vm.$tc(_vm.entry.name),"required":_vm.entry.hasRequiredValidator,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: !_vm.entry.metadata.multiple,
      }},on:{"update:search-input":function($event){_vm.searchInput = $event}},scopedSlots:_vm._u([(_vm.entry.metadata.translate)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.entry.metadata.itemText ? _vm.$t(item[_vm.entry.metadata.itemText]) : _vm.$t(item))+" ")]}}:null,{key:"no-data",fn:function(){return [_c(VListItem,{on:{"click":_vm.addCustomInput}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.entry.metadata.multiple ? _vm.$t("combobox.no-data.multi") : _vm.$t("combobox.no-data.single"))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}})]:_vm._e(),(_vm.displayMode === 'readonly')?_c('div',{staticClass:"readonly-wrapper"},[_c('div',{staticClass:"readonly-wrapper__label"},[_vm._v(_vm._s(_vm.$t(_vm.entry.name)))]),_c('div',{staticClass:"readonly-wrapper__value"},[_vm._v(_vm._s(_vm.displayList))])]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }