var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageReady)?_c('div',[(_vm.activityDefinition.version == 'v1')?_c('div',[_c('MyDocumentsView')],1):_c('div',[_c('authentication-interceptor',[_c('accept-policies-interceptor',[_c('page-header',{attrs:{"headerIconName":"mdi-file-document","headerTitle":"my-documents.page-title"}}),(_vm.activityDefinition)?_c('overview-component',{attrs:{"overview-definition-id":_vm.activityDefinition.generalDocuments.overviewDefinitionId,"entityType":'documents-overview',"path":'api/v2/documents/metadata',"additional-filters":_vm.additionalFilters,"additional-metadata":_vm.additionalMetadata,"can-create-new":true,"create-new":{
            title: 'my-documents.documents',
            subtitle: 'my-documents.create',
            icon: 'mdi-file-upload',
            largeImage: 'no-dossiers.svg',
          }},on:{"createNewClicked":_vm.showUploadDialog,"overviewControl":_vm.recieveOverviewControl,"actionButtonPressed":_vm.actionPressed}}):_vm._e()],1)],1)],1),_c('form-dialog-component',{attrs:{"form-dialog-definition-id":_vm.activityDefinition.generalDocuments.uploadDocumentDialogDefinitionId,"external-context":_vm.externalContext,"dialog-width":'600'},on:{"formDialogControl":_vm.recieveUploadDocumentControl}}),_c('form-dialog-component',{ref:"detailDialog",attrs:{"loading":_vm.savingDocument,"form-dialog-definition-id":_vm.rules.canUpdateFiles
        ? _vm.activityDefinition.generalDocuments.modifyDocumentDialogDefinitionId
        : _vm.activityDefinition.generalDocuments
            .detailsDocumentDialogDefinitionId,"external-context":_vm.externalContext,"dialog-width":"600"},on:{"formDialogControl":_vm.recieveDocumentDetailsControl}}),_c('share-document-dialog-component',{attrs:{"show":_vm.showShareDialog,"rules":_vm.rules,"document-item":_vm.documentItem,"external-context":_vm.externalContext}})],1):_c('div',[_c('loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }